// 获取bim路网数据接口
const dataListURL = `/gateway/hc-space/bimRoad/dataList`;
// 通过路网点名称获取bim路网数据接口
const dataListByNameURL = `/gateway/hc-space/bimRoad/dataListByName`;
// 导航接口步骤
const stepURL = `/gateway/d3m-base/maze/step`;
//新的演示接口
const bimRoadUrl = `/gateway/d3m-base/maze/poi`;
//类型接口
const poiType = `/gateway/d3m-base/maze/poiType`;
export { bimRoadUrl, dataListURL, dataListByNameURL, stepURL, poiType };
