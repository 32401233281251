<template>
  <div class="guideCom">
    <div class="tiao" @click="toNew">跳过</div>
    <div class="box1" v-if="oIndex == 1">
      <div class="box1-t">
        <div class="item-l">全部</div>
        <div class="item-c"></div>
        <div class="item-r">202</div>
      </div>
      <div class="box1-b">
        <div class="item-l">全部</div>
        <div class="item-c"></div>
        <div class="item-r">请输入终点</div>
      </div>
    </div>
    <div class="box1-other" v-if="oIndex == 1">
      <div class="img"><img src="./img/arrow1.png" alt="" /></div>
      <div class="xuhao">
        <div class="xuhaoImg"><img src="./img/xuhao1.png" alt="" /></div>
        <div class="txt">点击输入起点地址</div>
      </div>
      <div class="know" @click="know"><img src="./img/know.png" alt="" /></div>
    </div>
    <div class="box2" v-if="oIndex == 2" :style="oStyle">
      <div class="item">
        <div class="item-l">规划地面路径</div>
        <div class="item-r">规划地库路径</div>
      </div>
      <div class="item">
        <div class="item-l">规划人行路线</div>
        <div class="item-r">规划车行路线</div>
      </div>
    </div>

    <div class="box2-other" v-if="oIndex == 2">
      <div class="img"><img src="./img/arrow1.png" alt="" /></div>
      <div class="xuhao">
        <div class="xuhaoImg"><img src="./img/xuhao2.png" alt="" /></div>
        <div class="txt">点击选择规划路面路径或者地库路径</div>
      </div>
      <div class="know" @click="know"><img src="./img/know.png" alt="" /></div>
    </div>
    <div class="box3" v-if="oIndex == 3">
      <div class="item">
        <div class="item-l"><img src="./img/icon1.png" alt="" /></div>
        <div class="item-r">
          <div class="item-rt">203</div>
          <div class="item-rb">行政中心1号楼-办公室</div>
        </div>
      </div>
      <div class="item">
        <div class="item-l"><img src="./img/icon2.png" alt="" /></div>
        <div class="item-r">
          <div class="item-rt">203室</div>
          <div class="item-rb">行政中心2号楼-会议室</div>
        </div>
      </div>
      <div class="item">
        <div class="item-l"><img src="./img/icon3.png" alt="" /></div>
        <div class="item-r">
          <div class="item-rt">A203</div>
          <div class="item-rb">行政中心负1楼-车位</div>
        </div>
      </div>
      <div class="item">
        <div class="item-l"><img src="./img/icon4.png" alt="" /></div>
        <div class="item-r">
          <div class="item-rt">203室</div>
          <div class="item-rb">行政中心2号楼-食堂</div>
        </div>
      </div>
      <div class="item">
        <div class="item-l"><img src="./img/icon5.png" alt="" /></div>
        <div class="item-r">
          <div class="item-rt">203室</div>
          <div class="item-rb">行政中心2号楼-门岗</div>
        </div>
      </div>
    </div>
    <div class="box3-other" v-if="oIndex == 3">
      <div class="know" @click="know"><img src="./img/know.png" alt="" /></div>
      <div class="xuhao">
        <div class="xuhaoImg"><img src="./img/xuhao3.png" alt="" /></div>
        <div class="txt">点击选择终点地址</div>
      </div>
      <div class="img"><img src="./img/arrow2.png" alt="" /></div>
    </div>
    <div class="box4" v-if="oIndex == 4" :style="oStyle4">
      <div class="item"><img src="./img/enter.png" alt="" /></div>
    </div>
    <div class="box4-other" v-if="oIndex == 4">
      <div class="img"><img src="./img/arrow3.png" alt="" /></div>
      <div class="xuhao">
        <div class="xuhaoImg"><img src="./img/xuhao4.png" alt="" /></div>
        <div class="txt">点击规划路线</div>
      </div>
      <div class="know" @click="know"><img src="./img/know.png" alt="" /></div>
    </div>
    <div class="box5" v-if="oIndex == 5">
      <img src="./img/back.png" alt="" />
      <div class="box5-body" :style="oStyle5">
        <div class="item-t">规划中</div>
        <div class="item-c">
          <div class="item-ct">前往出口</div>
          <div class="item-cb">2#楼4号电梯</div>
        </div>
        <div class="item-b">第1步</div>
      </div>
    </div>
    <div class="box5-other" v-if="oIndex == 5">
      <div class="xuhao">
        <div class="xuhaoImg"><img src="./img/xuhao5.png" alt="" /></div>
        <div class="txt">根据规划，点击每一步查看路线详情</div>
      </div>
      <div class="img"><img src="./img/arrow4.png" alt="" /></div>
      <div class="know" @click="know"><img src="./img/know.png" alt="" /></div>
    </div>
  </div>
</template>

<script>
import {} from "../api";

export default {
  components: {},
  data() {
    return {
      oIndex: 1,
      oStyle: {},
      oStyle4: {},
      oStyle5: {},
      positionX: "",
      positionY: "",
      positionX4: "",
      positionY4: "",
      positionX5: "",
      positionY5: "",
      oWidth: document.documentElement.clientWidth,
      oHeight: document.documentElement.clientHeight,
    };
  },
  watch: {},
  created() {},
  mounted() {
    this.positionX = (this.oWidth / 375) * 22;
    this.positionY = (this.oWidth / 375) * 60;
    this.positionX4 = (this.oWidth / 375) * 30;
    this.positionY4 = (this.oWidth / 375) * 210;
    this.positionX5 = (this.oWidth / 375) * 255;
    this.positionY5 = (this.oWidth / 375) * 70;
    this.oStyle = { left: this.positionX + "px", top: this.positionY + "px" };
    this.oStyle4 = {
      left: this.positionX4 + "px",
      top: this.positionY4 + "px",
    };
    this.oStyle5 = {
      left: this.positionX5 + "px",
      top: this.positionY5 + "px",
    };
  },
  methods: {
    know() {
      if (this.oIndex < 5) {
        this.oIndex++;
        if (this.oIndex == 2) {
          this.positionX = (this.oWidth / 375) * 21;
          this.positionY = (this.oWidth / 375) * 140;
          this.$nextTick(() => {
            setTimeout(() => {
              this.oStyle = {
                left: this.positionX + "px",
                top: this.positionY + "px",
              };
            }, 0);
          });
        }
        if (this.oIndex == 4) {
          this.positionX4 = (this.oWidth / 375) * 285;
          this.positionY4 = (this.oWidth / 375) * 70;
          this.$nextTick(() => {
            setTimeout(() => {
              this.oStyle4 = {
                left: this.positionX4 + "px",
                top: this.positionY4 + "px",
              };
            }, 0);
          });
        }
        if (this.oIndex == 5) {
          console.log(this.oHeight, "0988");
          this.positionX5 = (this.oWidth / 375) * 10;
          this.positionY5 = this.oHeight - (this.oWidth / 375) * 100 - 31;
          this.$nextTick(() => {
            setTimeout(() => {
              this.oStyle5 = {
                left: this.positionX5 + "px",
                top: this.positionY5 + "px",
              };
            }, 0);
          });
        }
      } else {
        this.toNew();
      }
    },
    toNew() {
      this.$emit("toEmit");
    },
  },
};
</script>
<style lang="less" scoped>
.guideCom {
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  position: fixed;
  background: rgba(0, 0, 0, 0.75);
  z-index: 99;
  transition: all 2s;
  .tiao {
    position: absolute;
    right: 38px;
    top: 88px;
    font-size: 28px;
    font-weight: 400;
    color: #ffffff;
    line-height: 40px;
    z-index: 199;
  }
  .box1 {
    width: 562px;
    height: 168px;
    background: #ffffff;
    border-radius: 16px;
    position: absolute;
    top: 110px;
    left: 46px;
    padding: 0 116px 0 16px;
    box-sizing: border-box;
    .box1-t {
      padding: 20px 24px 22px;
      border-bottom: 2px solid #e7ebee;
      box-sizing: border-box;
      .item-c {
        width: 12px;
        height: 12px;
        background: #2d7ef8;
        margin: 0 20px;
        border-radius: 50%;
      }
      font-size: 26px;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.85);
      line-height: 36px;
      display: flex;
      align-items: center;
    }
    .box1-b {
      padding: 20px 24px 32px;
      box-sizing: border-box;
      .item-c {
        width: 12px;
        height: 12px;
        background: #dd2a28;
        margin: 0 20px;
        border-radius: 50%;
      }
      font-size: 26px;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.85);
      line-height: 36px;
      display: flex;
      align-items: center;
      .item-r {
        color: rgba(0, 0, 0, 0.5);
      }
    }
  }
  .box1-other {
    position: absolute;
    left: 274px;
    top: 300px;
    .img {
      width: 74px;
      height: 194px;
      margin-left: 120px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .xuhao {
      display: flex;
      align-items: center;
      .xuhaoImg {
        width: 48px;
        height: 48px;
        margin-right: 8px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .txt {
        font-size: 26px;
        font-weight: 400;
        color: #ffffff;
        line-height: 36px;
      }
    }
    .know {
      width: 138px;
      margin-top: 24px;
      margin-left: 92px;
      height: 62px;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  .box2 {
    background: #ffffff;
    transition: all 0.5s;
    box-shadow: 0px 0px 12px 10px rgba(207, 216, 231, 0.13);
    border-radius: 16px;
    position: absolute;
    .item {
      width: 536px;
      height: 84px;

      // top: 280px;
      // left: 42px;
      display: flex;
      padding: 0 0 0 24px;
      box-sizing: border-box;
      align-items: center;
      .item-l {
        width: 230px;
        height: 54px;
        background: #4188ff;
        border-radius: 27px;
        font-size: 24px;
        font-weight: 500;
        color: #ffffff;
        line-height: 54px;
        text-align: center;
        margin-right: 32px;
      }
      .item-r {
        font-size: 24px;
        font-weight: 500;
        color: rgba(0, 0, 0, 0.5);
        line-height: 84px;
      }
    }
  }

  .box2-other {
    position: absolute;
    left: 122px;
    top: 465px;
    .img {
      width: 74px;
      height: 194px;
      margin-left: 235px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .xuhao {
      display: flex;
      align-items: center;
      .xuhaoImg {
        width: 48px;
        height: 48px;
        margin-right: 8px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .txt {
        font-size: 26px;
        font-weight: 400;
        color: #ffffff;
        line-height: 36px;
      }
    }
    .know {
      width: 138px;
      margin-top: 24px;
      margin-left: 184px;
      height: 62px;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  .box3 {
    width: 688px;
    top: 460px;
    position: absolute;
    left: 30px;
    height: 576px;
    background: #ffffff;
    transition: all 2s;
    box-shadow: 0px 0px 12px 10px rgba(207, 216, 231, 0.13);
    border-radius: 16px;
    padding: 0 8px;
    box-sizing: border-box;
    .item {
      display: flex;
      align-items: center;
      padding: 16px 12px 20px;
      border-bottom: 2px solid #f9f9f9;
      .item-l {
        width: 26px;
        height: 26px;
        margin-right: 14px;
        font-size: 0;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .item-r {
        .item-rt {
          font-size: 28px;
          font-weight: 500;
          color: rgba(0, 0, 0, 0.85);
          line-height: 40px;
        }
        .item-rb {
          font-size: 24px;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.5);
          line-height: 34px;
        }
      }
    }
  }
  .box3-other {
    position: absolute;
    left: 214px;
    top: 112px;
    .img {
      width: 172px;
      height: 122px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .xuhao {
      display: flex;
      align-items: center;
      margin-left: 18px;
      .xuhaoImg {
        width: 48px;
        height: 48px;
        margin-right: 8px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .txt {
        font-size: 26px;
        font-weight: 400;
        color: #ffffff;
        line-height: 36px;
      }
    }
    .know {
      width: 138px;
      margin-bottom: 20px;
      margin-left: 112px;
      height: 62px;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  .box4 {
    width: 88px;
    height: 88px;
    background: #ffffff;
    border-radius: 44px;
    transition: all 1s;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    .item {
      width: 64px;
      height: 64px;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  .box4-other {
    position: absolute;
    left: 336px;
    top: 220px;
    .img {
      width: 118px;
      height: 170px;
      margin-left: 124px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .xuhao {
      display: flex;
      align-items: center;
      margin: 10px 0;
      .xuhaoImg {
        width: 48px;
        height: 48px;
        margin-right: 8px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .txt {
        font-size: 26px;
        font-weight: 400;
        color: #ffffff;
        line-height: 36px;
      }
    }
    .know {
      width: 138px;
      margin-bottom: 20px;
      margin-left: 72px;
      height: 62px;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  .box5 {
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    z-index: 108;
    img {
      width: 100%;
      height: 100%;
    }
    .box5-body {
      width: 240px;
      height: 200px;
      border-radius: 20px;
      background: #fff;
      transition: all 2s;
      position: absolute;
      //   left: 20px;
      //   bottom: 62px;
      padding: 10px 0 0;
      .item-t {
        text-align: center;
        font-size: 28px;
        font-weight: 500;
        color: #4188ff;
        line-height: 40px;
      }
      .item-c {
        padding-left: 20px;
        font-size: 28px;
        font-weight: 500;
        color: rgba(0, 0, 0, 0.5);
        line-height: 40px;
        margin: 15px 0;
        .item-cb {
          font-weight: 700;
          color: rgba(0, 0, 0, 0.85);
        }
      }
      .item-b {
        text-align: center;
        font-size: 28px;
        font-weight: 500;
        color: #4188ff;
        line-height: 40px;
      }
    }
  }
  .box5-other {
    position: absolute;
    width: 539px;
    height: 213px;
    left: 116px;
    bottom: 294px;
    z-index: 108;
    .img {
      width: 200px;
      height: 166px;
      position: absolute;
      left: 0;
      bottom: 0;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .xuhao {
      display: flex;
      align-items: center;
      margin: 10px 0;
      .xuhaoImg {
        width: 48px;
        height: 48px;
        margin-left: 62px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .txt {
        font-size: 26px;
        font-weight: 400;
        color: #ffffff;
        line-height: 36px;
      }
    }
    .know {
      width: 138px;
      position: absolute;
      bottom: 74px;
      left: 264px;
      height: 62px;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
</style>
