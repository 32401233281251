var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"guideCom"},[_c('div',{staticClass:"tiao",on:{"click":_vm.toNew}},[_vm._v("跳过")]),(_vm.oIndex == 1)?_c('div',{staticClass:"box1"},[_vm._m(0),_vm._m(1)]):_vm._e(),(_vm.oIndex == 1)?_c('div',{staticClass:"box1-other"},[_vm._m(2),_vm._m(3),_c('div',{staticClass:"know",on:{"click":_vm.know}},[_c('img',{attrs:{"src":require("./img/know.png"),"alt":""}})])]):_vm._e(),(_vm.oIndex == 2)?_c('div',{staticClass:"box2",style:(_vm.oStyle)},[_vm._m(4),_vm._m(5)]):_vm._e(),(_vm.oIndex == 2)?_c('div',{staticClass:"box2-other"},[_vm._m(6),_vm._m(7),_c('div',{staticClass:"know",on:{"click":_vm.know}},[_c('img',{attrs:{"src":require("./img/know.png"),"alt":""}})])]):_vm._e(),(_vm.oIndex == 3)?_c('div',{staticClass:"box3"},[_vm._m(8),_vm._m(9),_vm._m(10),_vm._m(11),_vm._m(12)]):_vm._e(),(_vm.oIndex == 3)?_c('div',{staticClass:"box3-other"},[_c('div',{staticClass:"know",on:{"click":_vm.know}},[_c('img',{attrs:{"src":require("./img/know.png"),"alt":""}})]),_vm._m(13),_vm._m(14)]):_vm._e(),(_vm.oIndex == 4)?_c('div',{staticClass:"box4",style:(_vm.oStyle4)},[_vm._m(15)]):_vm._e(),(_vm.oIndex == 4)?_c('div',{staticClass:"box4-other"},[_vm._m(16),_vm._m(17),_c('div',{staticClass:"know",on:{"click":_vm.know}},[_c('img',{attrs:{"src":require("./img/know.png"),"alt":""}})])]):_vm._e(),(_vm.oIndex == 5)?_c('div',{staticClass:"box5"},[_c('img',{attrs:{"src":require("./img/back.png"),"alt":""}}),_c('div',{staticClass:"box5-body",style:(_vm.oStyle5)},[_c('div',{staticClass:"item-t"},[_vm._v("规划中")]),_vm._m(18),_c('div',{staticClass:"item-b"},[_vm._v("第1步")])])]):_vm._e(),(_vm.oIndex == 5)?_c('div',{staticClass:"box5-other"},[_vm._m(19),_vm._m(20),_c('div',{staticClass:"know",on:{"click":_vm.know}},[_c('img',{attrs:{"src":require("./img/know.png"),"alt":""}})])]):_vm._e()])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"box1-t"},[_c('div',{staticClass:"item-l"},[_vm._v("全部")]),_c('div',{staticClass:"item-c"}),_c('div',{staticClass:"item-r"},[_vm._v("202")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"box1-b"},[_c('div',{staticClass:"item-l"},[_vm._v("全部")]),_c('div',{staticClass:"item-c"}),_c('div',{staticClass:"item-r"},[_vm._v("请输入终点")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"img"},[_c('img',{attrs:{"src":require("./img/arrow1.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"xuhao"},[_c('div',{staticClass:"xuhaoImg"},[_c('img',{attrs:{"src":require("./img/xuhao1.png"),"alt":""}})]),_c('div',{staticClass:"txt"},[_vm._v("点击输入起点地址")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item"},[_c('div',{staticClass:"item-l"},[_vm._v("规划地面路径")]),_c('div',{staticClass:"item-r"},[_vm._v("规划地库路径")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item"},[_c('div',{staticClass:"item-l"},[_vm._v("规划人行路线")]),_c('div',{staticClass:"item-r"},[_vm._v("规划车行路线")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"img"},[_c('img',{attrs:{"src":require("./img/arrow1.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"xuhao"},[_c('div',{staticClass:"xuhaoImg"},[_c('img',{attrs:{"src":require("./img/xuhao2.png"),"alt":""}})]),_c('div',{staticClass:"txt"},[_vm._v("点击选择规划路面路径或者地库路径")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item"},[_c('div',{staticClass:"item-l"},[_c('img',{attrs:{"src":require("./img/icon1.png"),"alt":""}})]),_c('div',{staticClass:"item-r"},[_c('div',{staticClass:"item-rt"},[_vm._v("203")]),_c('div',{staticClass:"item-rb"},[_vm._v("行政中心1号楼-办公室")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item"},[_c('div',{staticClass:"item-l"},[_c('img',{attrs:{"src":require("./img/icon2.png"),"alt":""}})]),_c('div',{staticClass:"item-r"},[_c('div',{staticClass:"item-rt"},[_vm._v("203室")]),_c('div',{staticClass:"item-rb"},[_vm._v("行政中心2号楼-会议室")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item"},[_c('div',{staticClass:"item-l"},[_c('img',{attrs:{"src":require("./img/icon3.png"),"alt":""}})]),_c('div',{staticClass:"item-r"},[_c('div',{staticClass:"item-rt"},[_vm._v("A203")]),_c('div',{staticClass:"item-rb"},[_vm._v("行政中心负1楼-车位")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item"},[_c('div',{staticClass:"item-l"},[_c('img',{attrs:{"src":require("./img/icon4.png"),"alt":""}})]),_c('div',{staticClass:"item-r"},[_c('div',{staticClass:"item-rt"},[_vm._v("203室")]),_c('div',{staticClass:"item-rb"},[_vm._v("行政中心2号楼-食堂")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item"},[_c('div',{staticClass:"item-l"},[_c('img',{attrs:{"src":require("./img/icon5.png"),"alt":""}})]),_c('div',{staticClass:"item-r"},[_c('div',{staticClass:"item-rt"},[_vm._v("203室")]),_c('div',{staticClass:"item-rb"},[_vm._v("行政中心2号楼-门岗")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"xuhao"},[_c('div',{staticClass:"xuhaoImg"},[_c('img',{attrs:{"src":require("./img/xuhao3.png"),"alt":""}})]),_c('div',{staticClass:"txt"},[_vm._v("点击选择终点地址")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"img"},[_c('img',{attrs:{"src":require("./img/arrow2.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item"},[_c('img',{attrs:{"src":require("./img/enter.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"img"},[_c('img',{attrs:{"src":require("./img/arrow3.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"xuhao"},[_c('div',{staticClass:"xuhaoImg"},[_c('img',{attrs:{"src":require("./img/xuhao4.png"),"alt":""}})]),_c('div',{staticClass:"txt"},[_vm._v("点击规划路线")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item-c"},[_c('div',{staticClass:"item-ct"},[_vm._v("前往出口")]),_c('div',{staticClass:"item-cb"},[_vm._v("2#楼4号电梯")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"xuhao"},[_c('div',{staticClass:"xuhaoImg"},[_c('img',{attrs:{"src":require("./img/xuhao5.png"),"alt":""}})]),_c('div',{staticClass:"txt"},[_vm._v("根据规划，点击每一步查看路线详情")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"img"},[_c('img',{attrs:{"src":require("./img/arrow4.png"),"alt":""}})])
}]

export { render, staticRenderFns }